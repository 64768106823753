.container {
  display: inline-block;
  width: 100%;
}
.style {
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #848891;
  border-radius: 8px;
  transition: 0.1s ease-in-out;

  &.inactive {
    color: #fff;
    background-color: #d9dbdf;
    border: 1px solid #d9dbdf;
    cursor: not-allowed;
  }
}
