@import "reset-css";

// fonts
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter/Inter-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter/Inter-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter/Inter-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter/Inter-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter/Inter-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
// /fonts

//  -----Default Styles-----
// Variables
$primary-color: #676b72;

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: $primary-color;
  background: #ecedef;
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: 1.6rem;
}

input,
textarea,
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  outline: none !important;
  appearance: none !important;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}
input::-webkit-textfield-decoration-container,
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}
input[type="checkbox"] {
  cursor: pointer;
}

button {
  font-family: "Inter", sans-serif;
  padding: 0;
  font-size: 1.6rem;
  color: $primary-color;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

//
.container {
  width: 100%;
  height: 100%;
  max-width: 195.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.primary-title {
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 38px;
  color: #848891;
}

.card-modal {
  background: #ffffff;
  // box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 3.2rem 2.4rem;
}

.primary-button,
.secondary-button {
  padding: 1.2rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  border-radius: 8px;
}
.primary-button {
  color: #fff;
  // background: #848891;
  background: #676b72;
}

.secondary-button {
  color: #676b72;
  border: 1px solid #676b72;
  background-color: transparent;
}

.nav-left {
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 36.3rem;
  background: #fff;
}

.err-message {
  font-size: 1.2rem !important;
  font-weight: 500;
  color: #a31621;
}

.button-as-link {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.015em;
  color: #4b4d54;
  text-decoration-line: underline;
}
.button-as-link:hover {
  text-decoration-line: none;
}
