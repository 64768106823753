.outlined,
.standard,
.textarea-standard,
.textarea-outlined {
  width: 100%;
  height: auto;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 400;
  color: #676b72;
  caret-color: #676b72;
  transition: 0.2s ease-in;

  &::placeholder {
    color: #c6c9d0;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #676b72 !important;
  }
}

.outlined {
  height: 4.8rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-radius: 6px;
  border: 1px solid #ecedef;
  &.error {
    border-color: #a31621 !important;
  }
}

.standard,
.textarea-standard,
.textarea-outlined {
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #ecedef;
  &.error {
    border-color: #a31621 !important;
  }
}
.textarea-standard {
  width: 100%;
  resize: none;
}
.textarea-outlined {
  padding: 1.2rem 1.6rem;
  border: 1px solid #ecedef;
  border-radius: 8px;
  resize: none;
}

.count {
  position: absolute;
  bottom: -1.6rem;
  right: 0;
  font-size: 1.2rem;
  letter-spacing: -0.025em;
  color: #c6c9d0;
}

.eye-icon-wrap {
  position: absolute;
  cursor: pointer;
  top: 1.2rem;
  right: 2rem;
}

.text-field-error {
  position: absolute;
  top: 105%;
  font-size: 1.2rem;
  color: #a31621;
}

.phone-container {
  width: 100%;
  & > div:first-child {
    display: none;
  }
}

.select-wrap {
  width: 100%;
  position: relative;
  .select {
    width: 100%;
    height: 4.8rem;
    padding: 1.3rem 3rem 1.3rem 1.2rem;
    font-size: 1.6rem;
    color: #d9dbdf;
    background: url("/assets/icons/arrow-down-icon.svg") no-repeat;
    background-position: calc(100% - 1.4rem) center !important;
    border: 1px solid #d9dbdf;
    border-radius: 0.6rem;
    cursor: pointer;
    option:disabled {
      color: #d9dbdf;
    }
    option:not(:first-child) {
      color: #676b72;
    }
    &.active {
      color: #676b72;
    }
    &.error {
      border: 1px solid #a31621 !important;
    }
  }
}

.file-upload-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  & > p {
    font-size: 1.2rem;
    font-weight: 500;
    color: #a0a5b0;
  }
}
.file-upload {
  height: 4.8rem;
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2.8rem;
  background-color: #f6f6f7;
  border-radius: 8px;

  button {
    height: 100%;
    padding: 0 1.6rem;
    font-weight: 500;
    color: #a0a5b0;
    background-color: #ecedef;
    border: 1px solid #e3e4e7;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    transition: 0.3 ease-in-out;
  }
  button:hover {
    border-color: #c6c9d0;
    background-color: #f6f6f7;
  }
  span {
    font-size: 1.6rem;
    color: #c6c9d0;
  }

  &.error {
    border: 1px solid #a31621 !important;
  }
}

.custom-checkbox {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.2rem;
  background-color: #fff;
  border: 1px solid #848891;
  outline: none;
  cursor: pointer;
  transition: 0.1s ease-in;
  &:checked {
    background-color: #848891;
  }
  &:checked::before {
    content: url("/assets/icons/check-mark-icon.svg");
    position: absolute;
    top: -10%;
    left: 10%;
    width: 80%;
    height: 80%;
  }
  &.error {
    border-color: #a31621 !important;
    // background-color: rgba(#a31621, 0.2);
  }
}

.custom-radio {
  margin: 0;
  position: relative;
  width: 1.667rem;
  height: 1.667rem;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #c6c9d0;
  cursor: pointer;

  &:checked {
    border-color: #667080;
  }
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: #667080;
    transition: 0.1s ease-in;
  }
  &:checked:before {
    width: 0.8335rem;
    height: 0.8335rem;
  }

  &.error {
    border-color: #a31621 !important;
    // background-color: rgba(#a31621, 0.2);
  }
}
