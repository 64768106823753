.reset-password {
  h1 {
    margin-bottom: 2.4rem;
  }
  .form-container {
    & > p {
      margin-bottom: 4.6rem;
      font-size: 1.4rem;
      line-height: 18px;
      letter-spacing: -0.02em;
    }
    .resend {
      display: flex;
      align-items: center;
      button {
        margin-left: 2.3rem;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.015em;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
    .resend-icon {
      animation-name: rotateIcon;
      animation-duration: 0.3s;
      animation-iteration-count: infinite;
      transform-origin: 50% 50%;
    }
    @keyframes rotateIcon {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-90deg);
      }
      50% {
        transform: rotate(-180deg);
      }
      75% {
        transform: rotate(-270deg);
      }
      100% {
        transform: rotate(-360deg);
      }
    }
    .form-row {
      margin-bottom: 3.2rem;
    }
  }
}

.flex {
  display: flex;

  .back-btn-wrap {
    position: absolute;
    top: 8.4rem;
    left: 6rem;
  }
  .col {
    min-height: 100vh;
  }
  .col:first-child {
    position: relative;
    flex: 38%;
    padding: 4rem 3.2rem;
  }
  .col:last-child {
    position: relative;
    flex: 62%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background-color: #fff;
  }
}

.logo {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 29px;
}
.placeholder-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-container {
  min-width: 42.3rem;

  h1 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 48px;
  }

  .form {
    .form-row {
      margin-bottom: 2.8rem;
    }
    .form-row__special {
      margin-bottom: 4rem;
      display: flex;
      justify-content: flex-end;
      a {
        font-size: 1.2rem;
        line-height: 18px;
        letter-spacing: -0.02em;
      }
    }
    label {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0.4rem;
    }
    .btn-wrap {
      width: 100%;
      margin-bottom: 2.4rem;
      button {
        height: 5.6rem;
      }
    }
    .link {
      font-size: 1.6rem;
      line-height: 22px;
      letter-spacing: -0.02em;
      a {
        display: inline-block;
        margin-left: 1.2rem;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
}
