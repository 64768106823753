.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 6.4rem;
  background: #fff;
  box-shadow: 3.8806px 3.8806px 10px rgba(0, 0, 0, 0.05);
  z-index: 100;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100% !important;

  .logo a{
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 39px;
  }

  .menu {
    display: flex;
    align-items: center;
  }

  // margin-left: 7.6rem;
  .navbar-nav {
    display: flex;
    align-items: center;
  }
  .navbar-nav__item {
    font-size: 1.6rem;
    line-height: 18px;
    letter-spacing: -0.02em;
    margin-right: 3.6rem;
  }
  .navbar-nav__item:last-child {
    margin-right: 0;
  }

  .active {
    font-weight: 600;
  }

  .user {
    display: flex;
    align-items: center;
    span {
      margin-right: 0.8rem;
      font-size: 1.6rem;
      line-height: 22px;
      letter-spacing: -0.02em;
    }
    div {
      cursor: pointer;
    }
  }
}
